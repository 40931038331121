.GamePage-link-margin {
  margin-bottom: 10px;
}

.GamePage {
  display: flex;
  flex-direction: column;
}

.GamePage-controls {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 20px;
}

