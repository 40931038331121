.Settings {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 50px;
}

.Settings-level-row {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
}

.Settings-level-row button {
  width: 86px;
  margin: 10px;
}

.Settings-level-colors {
  display: flex;
  flex-flow: row nowrap;
}

.Settings-color {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
  opacity: 0.85;
}

.Settings-cancel {
  width: 100px;
}
