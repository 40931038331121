body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-h {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-flex-end {
  align-items: flex-end;
}

button:focus, .btn:focus {
  outline: none;
}

.header-footer {
  height: 50px;
  padding: 10px;
  margin: 15px 0;
  color: grey;
  font-size: 18px;
  text-align: center;
}

